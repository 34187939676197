@font-face {
  font-family: 'pluss60-admin-icons';
  src: url('pluss60-admin-icons.eot?4tqzt8');
  src: url('pluss60-admin-icons.eot?4tqzt8#iefix') format('embedded-opentype'), url('pluss60-admin-icons.ttf?4tqzt8') format('truetype'),
    url('pluss60-admin-icons.woff?4tqzt8') format('woff'), url('pluss60-admin-icons.svg?4tqzt8#pluss60-admin-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='p60icon-'],
[class*=' p60icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pluss60-admin-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p60icon-star:before {
  content: '\e900';
}

.p60icon-star-o:before {
  content: '\e901';
}

.p60icon-add-image .path1:before {
  content: '\e902';
}

.p60icon-add-image .path2:before {
  content: '\e903';
  margin-left: -1em;
}

.p60icon-copy:before {
  content: '\e904';
}

.p60icon-add-circle .path1:before {
  content: '\e905';
}

.p60icon-add-circle .path2:before {
  content: '\e906';
  margin-left: -1em;
}

.p60icon-notifications:before {
  content: '\e907';
}

.p60icon-message:before {
  content: '\e908';
}

.p60icon-map:before {
  content: '\e909';
}

.p60icon-maintenance:before {
  content: '\e90a';
}

.p60icon-people:before {
  content: '\e90b';
}

.p60icon-facility .path1:before {
  content: '\e90c';
}

.p60icon-facility .path2:before {
  content: '\e90d';
  margin-left: -1em;
}

.p60icon-event:before {
  content: '\e90e';
}

.p60icon-news .path1:before {
  content: '\e90f';
}

.p60icon-news .path2:before {
  content: '\e910';
  margin-left: -1em;
}

.p60icon-dashboard:before {
  content: '\e911';
}

.p60icon-card:before {
  content: '\e912';
}

.p60icon-contact:before {
  content: '\e913';
}

.p60icon-settings:before {
  content: '\e914';
}

.p60icon-signin:before {
  content: '\e915';
}

.p60icon-paperclip:before {
  content: '\e916';
}

.p60icon-remove-circle:before {
  content: '\e917';
}

.p60icon-close:before {
  content: '\e918';
}

.p60icon-tiles:before {
  content: '\e919';
}

.p60icon-list-burger:before {
  content: '\e91a';
}

.p60icon-edit:before {
  content: '\e91b';
}

.p60icon-add:before {
  content: '\e91c';
}

.p60icon-info:before {
  content: '\e91d';
}

.p60icon-chevron_down:before {
  content: '\e91e';
}

.p60icon-chevron_left:before {
  content: '\e91f';
}

.p60icon-chevron_right:before {
  content: '\e920';
}

.p60icon-chevron_up:before {
  content: '\e921';
}

.p60icon-link:before {
  content: '\e922';
}

.p60icon-gallery:before {
  content: '\e923';
}
